import React from "react";

import GavinPotter from "../../photos/GavinPotter.JPEG";

import aig_logo from "../../photos/AIG-logo.png";
import american_amicable_logo from "../../photos/American-Amicable-logo.png";
import forsters_logo from "../../photos/Foresters-logo.png";
import john_hancock_logo from "../../photos/JohnHancockLogo.jpg";

import transamerica_logo from "../../photos/Transamerica-logo.png";
import ameirco_logo from "../../photos/Americo-logo.png";
import { TbBrandThreads } from "react-icons/tb";
import { Link } from "react-router-dom";
import { RiTwitterXFill } from "react-icons/ri";
import { RiTwitterXLine } from "react-icons/ri";
import { RiThreadsFill } from "react-icons/ri";
const Courtyard = () => {
  return (
    <div className="courtyard">
      <div className="courtyard-center">
        <div className="title">Gavin Potter Finance</div>

        <div className="displayhelper">
          <div className="info">
            <div className="info-portrait">
              <img
                className="info-photo"
                src={GavinPotter}
                alt="Gavin Potter Portrait"
              />
            </div>
            <div className="info-controls">
              <div className="info-contact">
                <Link
                  className="info-contact-twitter"
                  to="https://twitter.com/gpfinancex"
                >
                  <RiTwitterXLine />
                </Link>

                <Link
                  className="info-contact-threads"
                  to="https://www.threads.net/@gavinpotterfinance"
                >
                  <RiThreadsFill />
                </Link>
              </div>
              <Link
                to="/quote"
                onClick={() => {
                  window.scroll(0, 0);
                }}
                className="info-quote"
              >
                Quote
              </Link>
            </div>
            <ul className="states">
              <li className="states-list">Florida</li> {"          "}&bull;{" "}
              <li className="states-list">Georgia</li> &bull;{" "}
              <li className="states-list"> Utah </li> <br />
              <li className="states-list">Texas</li> &bull;{" "}
              <li className="states-list"> New Hampshire</li>
              <li className="states-list"> Oklahoma</li>
            </ul>
          </div>
        </div>
        <div className="displayhelper">
          <div className="affiliates">
            {/* <div className="affiliates-logoBox">
              <img
                className="affiliates-logo"
                src={ameirco_logo}
                alt="Americo"
              />
            </div> */}
            <div className="affiliates-logoBox">
              <img className="affiliates-logo" src={aig_logo} alt="AIG" />
            </div>
            {/* <div className="affiliates-logoBox">
              <img
                className="affiliates-logo"
                src={mutual_of_omaha_logo}
                alt="Mutual of Omaha"
              />
            </div> */}
            <div className="affiliates-logoBox">
              <img
                className="affiliates-logo"
                src={transamerica_logo}
                alt="Transamerica"
              />
            </div>
            <div className="affiliates-logoBox">
              <img
                className="affiliates-logo"
                src={john_hancock_logo}
                alt="John Hancock"
              />
            </div>

            <div className="affiliates-logoBox">
              <img
                className="affiliates-logo"
                src={forsters_logo}
                alt="John Hancock"
              />
            </div>
            <div className="affiliates-logoBox">
              <img
                className="affiliates-logo"
                src={american_amicable_logo}
                alt="American Amicable"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courtyard;
